import { useCallback } from 'react';

import { ApolloCache } from '@apollo/client';
import moment, { Moment } from 'moment-timezone';

import { CustomScalarTypeLinkOptions, createCustomScalarTypeLink } from '@pushpay/apollo-links';

import { MeDocument, MeQuery, TypeMapper, TypeName } from '@src/graphql/generated';

const fromUtcToTimezone = (date: unknown, timezone: string, allDay: boolean): Moment | null => {
	// all-day events from calendar feed shouldn't get their date converted using timezone
	const allDayCalendarEventTime = 'T00:00:00Z';
	const isAllDayCalendarEvent = allDay && typeof date === 'string' && date.endsWith(allDayCalendarEventTime);

	if (date) {
		return isAllDayCalendarEvent
			? moment(date.replace(allDayCalendarEventTime, ''))
			: moment.tz(date, 'UTC').tz(timezone);
	}

	return null;
};

const fromTimezoneToUtc = (date: unknown, timezone: string, allDay: unknown): string => {
	if (date && moment.isMoment(date)) {
		const isAllDay = typeof allDay === 'boolean' ? allDay : false;

		return isAllDay ? moment(date).utcOffset(0, true).format() : moment(date).tz(timezone, true).utc().format();
	}

	return '';
};

const mapToMoment = (getTimezone: () => string, allDay: unknown) => (value: unknown) => {
	const isAllDay = typeof allDay === 'boolean' ? allDay : false;

	if (value) {
		return Array.isArray(value)
			? value.map(item => fromUtcToTimezone(item, getTimezone(), isAllDay))
			: fromUtcToTimezone(value, getTimezone(), isAllDay);
	}

	return null;
};

const getCustomScalarTypeDictionary = (
	getTimezone: () => string,
	data?: Record<string, any>
): CustomScalarTypeLinkOptions['customScalarTypeDictionary'] =>
	new Map([
		[
			TypeName.URL,
			{
				mapFromScalarType: value => (value === null ? '' : value),
				mapToScalarType: value => {
					if (typeof value === 'string' && value !== '') {
						return value.trim();
					}
					return null;
				},
			},
		],
		[
			TypeName.JSONObject,
			{
				mapFromScalarType: value => JSON.stringify(value),
				mapToScalarType: value => {
					if (typeof value === 'string' && value !== '') {
						return JSON.parse(value);
					}
					if (typeof value === 'object') {
						return value;
					}
					return value;
				},
			},
		],
		[
			TypeName.Instant,
			{
				mapFromScalarType: mapToMoment(getTimezone, data?.allDay),
				mapToScalarType: value => {
					if (moment.isMoment(value)) {
						return fromTimezoneToUtc(value, getTimezone(), data?.allDay);
					}

					if (Array.isArray(value)) {
						return value.map(item => fromTimezoneToUtc(item, getTimezone(), data?.allDay)).filter(Boolean);
					}

					return null;
				},
			},
		],
		[
			TypeName.String,
			{
				mapFromScalarType: value => (value === null ? '' : value),
				mapToScalarType: value => {
					if (typeof value === 'string' && value !== '') {
						return value;
					}
					return null;
				},
			},
		],
	]);

export function useCustomScalarTypeLink(cache: ApolloCache<unknown>) {
	const getTimezone = useCallback(() => {
		const data = cache.readQuery<MeQuery>({ query: MeDocument });
		return moment.tz.guess(true) ?? data?.me?.olsonTimeZone;
	}, [cache]);

	return createCustomScalarTypeLink({
		typeMapper: TypeMapper,
		customScalarTypeDictionary: getCustomScalarTypeDictionary(getTimezone),
		getCustomScalarTypeDictionary: (data?: Record<string, any>) => getCustomScalarTypeDictionary(getTimezone, data),
	});
}
