import { useApolloClient } from '@apollo/client';

import { Button } from '@pushpay/button';
import { clsx } from '@pushpay/styles';

import { CardProps } from '@src/components/card';
import { DraggableIcon } from '@src/components/draggable';
import { Icon } from '@src/components/icon';
import { TagWithTooltip } from '@src/components/tag';
import { useCopyAndMoveDispatchContext } from '@src/context';
import { useFeature } from '@src/featureFlags';
import { ContainerType, GetContainerChildrenDocument, GetContainerChildrenQuery } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { useGetQueryAndMutationVars } from '@src/shared/hooks';

import { ParentContainer } from './ChildrenNavigation';
import useStyles from './destinationCardStyles';
import { SelectedContainer, ContainerChild, EventsContainerChildrenType } from './types';

type ElementEvent = React.MouseEvent<HTMLButtonElement | HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>;

type DestinationCardType = {
	card: ContainerChild;
	selectedCards: CardProps[];
	eventsContainerChildren?: EventsContainerChildrenType[];
	isRootLevelContainer?: boolean;
	disabledContainers?: string[];
	destinationContainer?: SelectedContainer | null;
	parentDestinationContainer?: ParentContainer;
	onSelect: (card: SelectedContainer) => void;
	onNavigate: (children: SelectedContainer) => void;
};

export const DestinationCard = ({
	card,
	eventsContainerChildren,
	parentDestinationContainer,
	disabledContainers,
	selectedCards,
	destinationContainer,
	onSelect,
	onNavigate,
}: DestinationCardType) => {
	const classes = useStyles(undefined);
	const { translate } = useTranslation('appDesign');
	const { hasCompatibleCards } = useCopyAndMoveDispatchContext();
	const isInAppCalendarEnabled = useFeature('InAppCalendar');
	const client = useApolloClient();
	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();

	const isContainer = card.__typename === 'ChildContainer';
	const isSelected = destinationContainer?.id === card.id;
	const isFeedContainer = isContainer && !!card.container?.feed?.id;

	const isDestinationAnEventsContainer =
		card.__typename === 'ChildContainer' && card.container.type === ContainerType.Events;

	const shouldDisableEventsContainer = () => {
		const doesDestinationHaveAnEventsContainerParent = parentDestinationContainer?.type === ContainerType.Events;

		if (!isDestinationAnEventsContainer) return false;

		const eventContainersToBeMoved = selectedCards.filter(
			selectedCard => selectedCard.containerType === ContainerType.Events
		);

		const hasSelectedEventContainersToBeMoved = eventContainersToBeMoved.length > 0;

		if (doesDestinationHaveAnEventsContainerParent && hasSelectedEventContainersToBeMoved) return true;

		const cachedResults = eventContainersToBeMoved.map(selectedCard =>
			client.readQuery<GetContainerChildrenQuery>({
				query: GetContainerChildrenDocument,
				variables: {
					organizationKey,
					platformCampusKey,
					applicationId,
					containerId: selectedCard.id,
				},
			})
		);

		const eventContainerChildren = cachedResults
			.filter(cachedResult => cachedResult?.organization?.application?.container?.type === ContainerType.Events)
			.map(cachedResult => cachedResult?.organization?.application?.container?.children.nodes);

		const hasNestedEventContainer = eventContainerChildren.some(children =>
			children?.some(
				child => child.__typename === 'ChildContainer' && child.container.type === ContainerType.Events
			)
		);

		if (hasNestedEventContainer) return true;

		return false;
	};

	const isDisabled =
		!isContainer ||
		disabledContainers?.includes(card.id) ||
		isFeedContainer ||
		!hasCompatibleCards(card.container.type, selectedCards) ||
		(shouldDisableEventsContainer() && isInAppCalendarEnabled);

	const icon: DraggableIcon = isContainer ? 'folder-1' : 'file-1';
	const cardIcon = (card.icon || icon) as DraggableIcon;
	const container: SelectedContainer = {
		id: card.id,
		name: card.name,
		isRootContainer: false,
		isEventsContainer: isDestinationAnEventsContainer,
		eventsContainerChildren,
	};
	const campusName = isContainer && card.container.campus?.name;
	const isCardHidden = card.hidden;

	const onSelectHandler = (e: ElementEvent) => {
		e.stopPropagation();
		onSelect(container);
	};
	const onClickHandler = (e: ElementEvent) => {
		e.stopPropagation();
		onNavigate(container);
	};
	const onKeyDownHandler = (e: ElementEvent) => {
		if ('key' in e && e.key === 'Enter') onSelectHandler(e);
	};

	const navigationButton = isContainer ? (
		<Button
			className={classes.navigationButton}
			disabled={isDisabled}
			displayStyle="unstyled"
			type="button"
			onClick={onClickHandler}
		>
			<Icon classes={{ root: clsx(classes.rightIcon, isDisabled && classes.disabled) }} name="arrow-68" />
		</Button>
	) : null;

	const containerCampusTags = (
		<div className={classes.tagsContainer}>
			{campusName && (
				<TagWithTooltip classes={{ tag: classes.campusTag }} displayStyle="success" label={campusName} />
			)}
			{isCardHidden && <TagWithTooltip displayStyle="default" label={translate('tag.hidden')} />}
		</div>
	);

	return (
		<li key={card.id}>
			<div
				className={clsx(classes.card, isDisabled && classes.disabled, isSelected && classes.active)}
				role="button"
				tabIndex={0}
				{...(!isDisabled && {
					onClick: onSelectHandler,
					onDoubleClick: onClickHandler,
					onKeyDown: onKeyDownHandler,
				})}
			>
				<div className="iconContainer">
					<Icon classes={{ root: classes.leftIcon }} name={cardIcon} />
				</div>
				<div className={classes.nameContainer}>{card.name}</div>
				{containerCampusTags}
				{navigationButton}
			</div>
		</li>
	);
};
