import { Suspense, useRef } from 'react';

import { Outlet } from 'react-router-dom';

import { Footer } from '@pushpay/shell';

import { useNotificationWrapper } from '@src/components/notification';
import {
	ContentContextProvider,
	BreadcrumbContextProvider,
	ErrorContextProvider,
	FormContextProvider,
	FeedProcessProvider,
	CopyAndMoveContextProvider,
} from '@src/context';
import { DraggableDataContextProvider } from '@src/context/draggableData/draggablesDataContext';
import { ScrollToLastPagePositionContextProvider } from '@src/context/scrollToLastPagePositionContext';
import { useMyOrganization } from '@src/myContext';

import { AppDesignDragAndDrop } from './AppDesignDragAndDrop';
import { useStyles } from './appDesignStyles';
import { Actions } from './components/Actions';
import { Breadcrumbs } from './components/Breadcrumb';
import { DraggablePane } from './components/DraggablePane';
import { Preview } from './components/Preview';

export const AppDesign = () => {
	const classes = useStyles(undefined);
	const ref = useRef<HTMLDivElement>(null);
	const wrapperRef = useNotificationWrapper();
	const { details } = useMyOrganization();

	const getFooterLogo = () => {
		if (details?.isGivingPlatformActive && details?.hasCCB) {
			return details?.catholicSoftwareStatus ? 'parishStaq' : 'churchStaq';
		}
		return 'pushpay';
	};

	return (
		<main className={classes.root}>
			<ContentContextProvider>
				<ScrollToLastPagePositionContextProvider containerRef={ref}>
					<CopyAndMoveContextProvider>
						<DraggableDataContextProvider>
							<AppDesignDragAndDrop>
								<aside className={classes.sidePanel} data-scrollable>
									<DraggablePane data-pp-at-target="draggables-pane" />
								</aside>
								<BreadcrumbContextProvider>
									<ErrorContextProvider>
										<FeedProcessProvider>
											<div ref={wrapperRef} className={classes.main}>
												<div className={classes.header}>
													<Breadcrumbs data-pp-at-target="breadcrumbs" />
													<Actions data-pp-at-target="actions" />
												</div>
												<div className={classes.content} data-scrollable>
													<FormContextProvider>
														<div ref={ref} className={classes.pageContainer}>
															<div className={classes.page}>
																<Suspense>
																	<Outlet />
																</Suspense>
															</div>
															<Footer
																classes={{ root: classes.footer }}
																logo={getFooterLogo()}
															/>
														</div>
														<Preview data-pp-at-target="preview" />
													</FormContextProvider>
												</div>
											</div>
										</FeedProcessProvider>
									</ErrorContextProvider>
								</BreadcrumbContextProvider>
							</AppDesignDragAndDrop>
						</DraggableDataContextProvider>
					</CopyAndMoveContextProvider>
				</ScrollToLastPagePositionContextProvider>
			</ContentContextProvider>
		</main>
	);
};
